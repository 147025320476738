import React from 'react'
import Banner from '../components/ads/banner'
function TaxSlabs() {
    return (
        <>
            <div className='row mb-4 print-hide'>
                <div className='col-12'>
                    <h1>Income Tax Slabs FY 2024-2025<span className='d-none'> tax brackets 2024</span></h1>
                    <p style={{ fontSize: "18px" }}>In Pakistan, income tax is levied on individuals and businesses based on their taxable income. Taxable income is the total income earned during a tax year, minus any deductions and exemptions that are allowed under the tax laws. Income tax rates in Pakistan are progressive, meaning that higher levels of income are taxed at higher rates.</p>
                    <p>The recent modifications to income tax brackets for employed individuals as outlined in the federal budget for the fiscal year 2024-25 proposed some changes in tax slabs. Commencing from July 1, 2024, individuals with annual taxable incomes exceeding Rs. 600,000 will now be subject to a high income tax rate.</p>

                    <ol className='p-0'>
                        <li>
                            Where the taxable salary income does not exceed <b>Rs. 600,000</b> the rate of income tax is <b>0%.</b>
                        </li>
                        <li>
                            Where the taxable salary income exceeds <b>Rs. 600,000</b> but does not exceed <b>Rs. 1,200,000</b> the rate of income tax is <b>5%</b> of the amount exceeding <b>Rs. 600,000.</b>
                        </li>
                        <li>
                            Where the taxable salary income exceeds <b>Rs. 1,200,000</b> but does not exceed <b>Rs. 2,200,000</b> the rate of income tax is <b>Rs. 30,000 + 15%</b> of the amount exceeding <b>Rs. 1,200,000.</b>
                        </li>
                        <li>
                            Where the taxable salary income exceeds <b>Rs. 2,200,000</b> but does not exceed <b>Rs. 3,200,000</b> the rate of income tax is <b>Rs. 180,000 + 25%</b> of the amount exceeding <b>Rs. 2,200,000.</b>
                        </li>
                        <li>
                            Where the taxable salary income exceeds <b>Rs. 3,200,000</b> but does not exceed <b>Rs. 4,100,000</b> the rate of income tax is <b>Rs. 430,000 + 30%</b> of the amount exceeding <b>Rs. 3,200,000.</b>
                        </li>
                        <li>
                            Where the taxable salary income exceeds <b>Rs. 4,100,000</b> the rate of income tax is <b>Rs. 700,000 + 35%</b> of the amount exceeding <b>Rs. 4,100,000.</b>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="row mb-4 print-hide">
                <div className="col-12">
                    <h2>Sales Tax Calculator Pakistan</h2>
                    <p>A sales tax calculator is a tool that can be used to calculate the sales tax on a purchase in Pakistan. Sales tax is a tax on the sale of goods and services, and it is typically added to the price of the goods or services being purchased.</p>
                    <p>In Pakistan, sales tax is levied at the federal and provincial levels. The federal government levies a general sales tax (GST) at a rate of 18% on most goods and services, while the provincial governments levy their own sales taxes at varying rates.</p>
                    <p>To use a sales tax calculator in Pakistan, you will need to know the following information:</p>
                    <ol>
                        <li>The total price of the goods or services being purchased.</li>
                        <li>The applicable sales tax rate. This can be found by adding the federal GST rate of 18% and the applicable provincial sales tax rate.</li>
                    </ol>
                    <p>To calculate the sales tax on a purchase in Pakistan, you can use the following formula:</p>
                    <p><strong>Total sales tax = Total price x (Sales tax rate / 100)</strong></p>
                    <p>For example, if you are purchasing goods for PKR 100,000 and the applicable sales tax rate is 18%, the total sales tax would be calculated as follows:</p>
                    <p><strong>Total sales tax = 100,000 x (18 / 100) = PKR 17,000</strong></p>
                    <p>It is important to note that sales tax rates and rules can change periodically, so it is always a good idea to consult with a tax professional or refer to the relevant government agency for the most up-to-date information.</p>
                </div>
            </div>
            <div className='row mb-4 print-hide'>
                <div className='col-12 text-center'>
                    <Banner></Banner>
                </div>
            </div>
        </>
    );
}

export default TaxSlabs;