import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import TaxCalculator from "../components/tax-calculator"

const IndexPage = () => (
  <Layout>
    {/* <Seo title="Tax Calculator Pakistan - 2023-2024" /> */}
    <TaxCalculator></TaxCalculator>
  </Layout>
)
export const Head = () => <Seo title="Income Tax Calculator Pakistan - 2024-25" />

export default IndexPage
